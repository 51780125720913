import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import CurrentProject from "../components/CurrentProject"

const WebsitePage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Website</h1>
            {CurrentProject()}
            <p>Its a simple (borderline blog) style website. I don't think there's much to say. Its built using <a href="https://www.gatsbyjs.com/">Gatsby</a> and <a href="https://reactjs.org/">React</a>. The project originally started in 2016. It was on again off again for many months until fall 2019 when a class required us to develop and publish a website. At the time, I had the best intentions of keeping it updated. That didn't end up happening, mostly because it was a mess of copy-paste and python scripts to try and make the theme consistent across all pages. That's what's motivated the recent re-write (and also the fact that im currently semi-actively job searching).</p>
            <p>So far, the Gatsby React stack has made editing a much more pleasant experience. Hopefully that means that things will be kept up to date in the future. However, there is one key process that is still manual: the layout and cross linking. I'm a big fan of Markdown; I used it for many course projects because it was fast to write and I could render it to a very pretty pdf for submission online. I would love to write Markdown for the website and have it generate webpages automatically. I know I could automate it with Python, but also some plugin must exist to handle this. Thats a project for after the website's content is updated to reflect the last three years. </p>
            <p>I really want to not care about frontend things. However, the more I do, the more it becomes a really easy to display output. So this is my middle ground; I'm going to learn enough webapp / frontend stuff to be able to brute force my way though the process but not enough the get a job doing it. I'm going to find ways that work but probably not the best way. I'm happy with how the website feels and flows, but I acknowledge that it could be better (especially on how it looks). However, thats not, and probably will never be a priority; I have <a href="/current">other things</a> that are more interesting.</p>
            <p>As I wrote in 2019, "Perhaps in another three years I will get around to fixing that". I'm leaving this here because its been three years since I wrote that and hopefully the next round of updates will be sooner than three years from now.</p>
            {FooterBar()}
        </div>
    )
}

export default WebsitePage